<template>
    <div>
        <h1>Shop Dashboard</h1>
    </div>
</template>

<script>

export default {
    name: 'Home'

};
</script>
